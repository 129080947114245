// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-project-detail-js": () => import("./../src/templates/project-detail.js" /* webpackChunkName: "component---src-templates-project-detail-js" */),
  "component---src-templates-news-item-js": () => import("./../src/templates/news-item.js" /* webpackChunkName: "component---src-templates-news-item-js" */),
  "component---src-templates-page-js": () => import("./../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-component-library-js": () => import("./../src/pages/component-library.js" /* webpackChunkName: "component---src-pages-component-library-js" */),
  "component---src-pages-home-js": () => import("./../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-projects-js": () => import("./../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-quiz-js": () => import("./../src/pages/quiz.js" /* webpackChunkName: "component---src-pages-quiz-js" */)
}

